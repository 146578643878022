import React, { useState, useEffect } from "react"
import * as clipboard from "clipboard-polyfill/text"
import cx from "classnames"
import { Link } from "gatsby"
import siteConfig, { getBuyerAppUrl, getHeroAppUrl } from "../../utils/config"
import useLocation from "../../utils/useLocation"
import Hamburger from "../../images/icons/hamburger.inline.svg"
import reddoorLogo from "../../images/reddoor-logo.svg"
import reddoorLogoDark from "../../images/reddoor-logo-dark.svg"
import appstoreLogo from "../../images/appstore-download.svg"
import playLogo from "../../images/play-download.svg"
import * as classes from "./header.module.css"

const navigationMap = [
  {
    text: "Home",
    to: "https://reddoor.com/home",
    external: true,
  },
  {
    text: "Agents",
    to: "https://reddoor.com/hero",
    external: true,
  },
  {
    text: "FAQs",
    to: "https://help.reddoor.com/en/",
    external: true,
  },
]

interface HeaderProps {
  darkHeader?: boolean
}

const Header: React.FunctionComponent<HeaderProps> = ({
  darkHeader = false,
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isAgentsPage, setIsAgentsPage] = useState(false)
  const [isPropertyPage, setIsPropertyPage] = useState(false)
  const [agentDeepLink, setAgentDeepLink] = useState("")
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname.startsWith("/agents") && !pathname.includes("/properties")) {
      setIsPropertyPage(false)
      setIsAgentsPage(true)
    }
    if (pathname.startsWith("/agents") && pathname.includes("/properties")) {
      setIsAgentsPage(false)
      setIsPropertyPage(true)

      const agentID = pathname
        .split("/agents/")
        .pop()!
        .split("/")
        .shift()

      fetch(`${siteConfig.apiUrl}/agents/${agentID}`)
        .then(response => response.json())
        .then(agent => setAgentDeepLink(agent.deepLink))
    }
  }, [pathname])

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <header
      className={cx(classes.headerWrapper, darkHeader && classes.headerDark)}
    >
      <div className={cx("container", classes.headerContainer)}>
        <div className={classes.logo}>
          <a href="https://reddoor.com">
            <img
              src={darkHeader ? reddoorLogoDark : reddoorLogo}
              alt="RedDoor"
            />
          </a>
        </div>

        <nav
          className={cx(classes.nav, menuOpen && classes.navActive)}
          id="navigation"
        >
          {navigationMap.map((link, i) => {
            if (link.external) {
              return (
                <a
                  href={link.to}
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.text}
                </a>
              )
            }
            return (
              <Link
                to={link.to}
                activeClassName={classes.navActiveLink}
                key={i}
                onClick={() => setMenuOpen(false)}
              >
                {link.text}
              </Link>
            )
          })}
          <section className={cx(classes.appstoreLink)}>
            <a
              style={{ cursor: "pointer" }}
              onMouseDown={async e => {
                const { button } = e

                if (isPropertyPage && !agentDeepLink) {
                  return window.open(getBuyerAppUrl("ios"))
                }

                if (isPropertyPage) {
                  await clipboard.writeText(agentDeepLink)
                }

                if (button === 0) {
                  window.open(
                    isPropertyPage
                      ? agentDeepLink
                      : isAgentsPage
                      ? getHeroAppUrl("ios")
                      : getBuyerAppUrl("ios")
                  )
                }
                if (button === 1) {
                  const newTab = window.open(
                    isPropertyPage
                      ? agentDeepLink
                      : isAgentsPage
                      ? getHeroAppUrl("ios")
                      : getBuyerAppUrl("ios"),
                    "_blank"
                  )
                  newTab && newTab.focus()
                }
              }}
            >
              <img src={appstoreLogo} alt="Download on the App store" />
            </a>
            <a
              style={{ cursor: "pointer" }}
              onMouseDown={async e => {
                const { button } = e
                if (isPropertyPage && !agentDeepLink) {
                  return window.open(getBuyerAppUrl("ios"))
                }

                if (isPropertyPage) {
                  await clipboard.writeText(agentDeepLink)
                }

                if (button === 0) {
                  window.open(
                    isPropertyPage
                      ? agentDeepLink
                      : isAgentsPage
                      ? getHeroAppUrl("ios")
                      : getBuyerAppUrl("ios")
                  )
                }
                if (button === 1) {
                  const newTab = window.open(
                    isPropertyPage
                      ? agentDeepLink
                      : isAgentsPage
                      ? getHeroAppUrl("ios")
                      : getBuyerAppUrl("ios"),
                    "_blank"
                  )
                  newTab && newTab.focus()
                }
              }}
            >
              <img src={playLogo} alt="Get it on Google Play" />
            </a>
          </section>
        </nav>

        <button
          onClick={handleToggleMenu}
          className={classes.mobileNavTrigger}
          type="button"
          aria-label="Menu"
          aria-controls="navigation"
        >
          <Hamburger className={cx("ham", menuOpen && "active")} />
        </button>
      </div>
    </header>
  )
}

export default Header
