import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import TwitterIcon from "../../images/icons/twitter.inline.svg"
import InstagramIcon from "../../images/icons/instagram.inline.svg"
import FacebookIcon from "../../images/icons/facebook.inline.svg"
import reddoorLogoBlack from "../../images/reddoor-logo-black.svg"
import equalHousingLogo from "../../images/equal-housing-opportunity.svg"
import * as classes from "./footer.module.css"

const year = new Date().getFullYear()

const Footer: React.FunctionComponent = () => {
  return (
    <footer className={classes.footerWrapper}>
      <div className={cx("container", classes.footerContainer)}>
        <div>
          <div className={classes.footerHeading}>
            <img src={reddoorLogoBlack} alt="RedDoor" />
          </div>
          <p>1161 Mission Street #401, San Francisco, CA, 94103</p>
          <p>NMLS #1838659, DRE #02084298</p>
          <p>© {year} MortgageHub, Inc. All rights Reserved.</p>
        </div>
        <div className={classes.footerRight}>
          <div className={classes.footerHeading}>
            <a
              href="https://twitter.com/RedDoorHQ"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter Account"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.facebook.com/reddoorhq"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook Page"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.instagram.com/reddoorhq"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <InstagramIcon />
            </a>
            <span className={classes.spacer} />
            <div className={classes.equalHousingLogo}>
              <img src={equalHousingLogo} alt="Equal Housing Opportunity" />
            </div>
          </div>
          <p>
            844-337-4821 |{" "}
            <a href="mailto:team@reddoor.com">team@reddoor.com</a>
          </p>
          <p>
            <a href="https://reddoor.com/terms" target="_blank">
              Terms of Service
            </a>
          </p>
          <p>
            <a href="https://reddoor.com/privacy" target="_blank">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
