interface Config {
  apiUrl: string
  buyerAppInvitationUrl: string
}

interface ConfigMap {
  staging: Config
  production: Config
}

const configurations: ConfigMap = {
  staging: {
    apiUrl: "https://kube.reddoor.dev/api",
    buyerAppInvitationUrl: "https://reddoor.page.link",
  },
  production: {
    apiUrl: "https://api.reddoor.com/api",
    buyerAppInvitationUrl: "https://invite.reddoor.com",
  },
}

const stagingDomains = [
  /localhost/,
  /reddoor\.netlify\.com$/,
  /reddoor\.netlify\.app$/,
]

const environment = (() => {
  if (typeof window !== "undefined") {
    const host = window.location.hostname
    if (stagingDomains.some(rx => rx.test(host))) {
      return "staging"
    }
  }
  return "production"
})()

const config = configurations[environment]

export const getBuyerAppUrl = (
  platform: "auto" | "ios" | "android" = "auto"
) => {
  const iosUrl =
    "https://itunes.apple.com/us/app/reddoor-get-your-dream-home/id1452922713?mt=8"
  const androidUrl =
    "https://play.google.com/store/apps/details?id=com.reddoor.home.production"

  if (typeof window !== "undefined") {
    const query = document.location.search || ""
    const match = query.match(/invite=([A-Za-z0-9]+)/)

    if (Array.isArray(match) && match.length > 1) {
      const [_, inviteCode] = match
      return `${config.buyerAppInvitationUrl}/${inviteCode}`
    }
  }

  if (platform === "auto") {
    return isAndroid() ? androidUrl : iosUrl
  }

  return platform === "android" ? androidUrl : iosUrl
}

export const isAndroid = () => {
  if (typeof navigator === "undefined") {
    return false
  }
  const ua = navigator.userAgent.toLowerCase()
  return ua.includes("android")
}

export const getHeroAppUrl = (
  platform: "auto" | "ios" | "android" = "auto"
) => {
  const iosUrl =
    "https://apps.apple.com/us/app/reddoor-hero-for-agents/id1454966065"
  const androidUrl = "https://app.reddoor.com/android"

  if (platform === "auto") {
    return isAndroid() ? androidUrl : iosUrl
  }

  return platform === "android" ? androidUrl : iosUrl
}

export default config
