import React from "react"
import "typeface-open-sans"
import Header from "./header"
import Footer from "./footer"

interface LayoutProps {
  darkHeader?: boolean
}

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  darkHeader,
}) => {
  return (
    <>
      <Header darkHeader={darkHeader} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
